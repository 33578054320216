import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import React, { useRef, useState, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Modal,
  Grid,
} from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { getUsers,userBadgePrinted,changePrintstatus } from '../redux/userSlice';
import { QrCodePage } from './QrCodePage';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';
import FormModal from './FormModal';
import BackdropLoading from './BackdropLoading';
import { ScanDetection } from './ScanDetection';




// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Reg.No', alignRight: false },
  { id: 'company', label: 'Full Name', alignRight: false },
  { id: 'role', label: 'Category', alignRight: false },
  { id: 'isVerified', label: 'Email', alignRight: false },
  { id: 'isVerified', label: 'Mobile', alignRight: false },
  { id: 'print', label: 'print', alignRight: false },
  { id: 'Edit', label: 'Edit', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  

export default function Attendees() {
  const regNumFromScanDetection = ScanDetection()
  const qrCodevalue = regNumFromScanDetection ? regNumFromScanDetection.replaceAll('Shift', '') : null


  const dispatch = useDispatch();
  const  {users,error,loading}  = useSelector((state) => ({ ...state.users}));
  console.log("users",users)
  const [open, setOpen] = useState(false);
  const [openformmodal, setOpenFormModal] = useState(false);
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [qrvalue, setQrValue] = useState('');
  const [attende, setAttende] = useState('');
  const [note, setNote] = useState('');
  const [reference, setReference] = useState('');


  const[edit,setEdit] = useState(false);
  const [modalregnum,setModalregnum] = useState(null)

  const componentRef = useRef(null);


useEffect(() => {
  dispatch(getUsers());
},[]);

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
  setEdit(false)
};
 const callback = ()=>{
  setOpenFormModal(false)
  setEdit(false)
 }
const handlePrint = () => {
  setOpen(false);
  const reg_num =  {reg_num : qrvalue}
  dispatch(changePrintstatus({reg_num}))
  dispatch(userBadgePrinted({reg_num}))
};

const handleFormOpen = () => {
  setOpenFormModal(true);
  setEdit(false)
};
 

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  
  useEffect(()=>{
    setPage(0);
    setFilterName(qrCodevalue);
},[qrCodevalue])

  const setQrvaluefunc = (id,name,note,reference) =>{
    setQrValue(id)
    setAttende(name)
    setNote(note)
    setReference(reference)

  };

  const handleEdit = (reg_num)=>{
    setEdit(true)
    setModalregnum(reg_num)
    setOpenFormModal(true);
  }


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;
  

if(users.length){
  
  var filteredUsers = applySortFilter(users, getComparator(order, orderBy), filterName);
  
function descendingComparator(a, b, orderBy) {
    
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  console.log('array',array)
 
  if (query) {

     return filter(array, (_user) =>{
        return(
           _user.reg_num.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
           _user.full_name.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
           _user.mob_num.toLowerCase().indexOf(query.toLowerCase()) !== -1 ||
           _user.email_id.toLowerCase().indexOf(query.toLowerCase()) !== -1 
        )
      })
     
     
  }
  return stabilizedThis.map((el) => el[0]);
}

}

  const isNotFound = !filteredUsers?.length && !!filterName;



  return (
    <>

      <Helmet>
      <title> Badging.com </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Attendees
          </Typography>
          <Button variant="contained" onClick={handleFormOpen}   startIcon={<Iconify icon="eva:plus-fill" />}>
          Add Attendee
          </Button>
        </Stack>
        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={users?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  { users?.length ?   filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,inx) => {
                    const { reg_num,full_name,category,mob_num,email_id,printed,note,reference} = row;
                    const selectedUser = selected.indexOf(full_name) !== -1;
attende
                    return (
                      <TableRow hover key={inx} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, full_name)} />
                        </TableCell>
                        <TableCell align="left">{reg_num}</TableCell>
                        <TableCell align="left">{full_name}</TableCell>
                        <TableCell align="left">{category == '1' ?  'Accompanying Person' : category == '2' ? 'Delegate' : category == '3' ? 'Executive council' :  category == '4' ? 'Executive council' :   category == '5' ? 'Exhibitor'  :  category == '6' ? 'Faculty'  :  category == '7' ? 'Office Bearer' :   category == '8' ? 'Organizing Committee' : 'N/A'}</TableCell>
                        <TableCell align="left">{email_id}</TableCell>
                        <TableCell align="left">{mob_num}</TableCell>
                        <TableCell align="left"> 
       
                         <Button variant="contained" color={(printed === '0' && 'primary') || 'error'} onClick= {() => setQrvaluefunc(reg_num,full_name,note,reference)}>
              {printed === '0' ? 'print' : 'printed'}
            </Button >
            </TableCell>
            <TableCell align="left">
              <Button variant="contained" color="info" onClick={() =>handleEdit(reg_num)}>Edit</Button>
            </TableCell>
                      </TableRow>
                    );
                  }) : ''}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

<Container style={{marginTop:"50px",padding:"50px"}}>
  <Card style={{padding:"50px"}}>  
  <ReactToPrint
        trigger={() => <Button style={{margin:"auto",display:"flex"}} variant="contained"  > Print this out</Button >}
        content={() => componentRef.current}
        onAfterPrint={() => handleClickOpen()}
      />
    
      { qrvalue.length > 0 && 
      <div ref={componentRef} style={{marginTop:"100px"}}>
        
  <QrCodePage qrvalue={qrvalue} attende={attende} note={note} reference={reference}     />
    </div>
    }

  </Card>
</Container>
      <div>
      <Dialog
        open={open}
        edit={edit}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Have you Printed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handlePrint} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
      </div>
      <div>
      <FormModal 
      openformmodal={openformmodal}
      callback={callback}
      edit={edit}
      modalregnum={modalregnum}
      />
      </div>
      <BackdropLoading loading={loading}/>
    </>
  );
}