import { Navigate, useRoutes, Route, Routes, Outlet  } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import Attendees from './pages/Attendees ';
import { ScanDetection } from './pages/ScanDetection';
import Scan from './pages/Scan';
import Certificate from './pages/Certificate';
import FoodScan from './pages/FoodScan';
import FwhScan from './pages/FwhScan';
import Workshop from './pages/Workshop';
import HeadCount from './pages/HeadCount';
import KitbagScan from './pages/KitbagScan';
import Admin from './Admin/Admin';
import AdminRoute from './AdminRoute';
import PrivateRoute from './protected/PrivateRoute';
import ScanBadge from './pages/ScanBadge';
import SaveDelegateData from './pages/SaveDelegateData';
import ExbhitorDashboard from './pages/ExbhitorDashboard';
// // ----------------------------------------------------------------------







export default function Router() {
  return (
    <>
      <Routes>
        <Route exact path="/login" element={<LoginPage />} />
        <Route path='' element={<PrivateRoute/>}>
        <Route  element={<DashboardLayout />}>
        <Route path='/app' element={<DashboardAppPage/>}/>
       <Route path="/user" element={<UserPage />} />
          <Route path="/Attendees" element={<Attendees />} />
          <Route path="/aptSet" element={<ScanDetection />} />
          <Route path="/scan/:halltype" element={<Scan />} />
          <Route path="/FwhScan/:halltype" element={<FwhScan />} />
          <Route path="/Certificate" element={<Certificate />} />
          <Route path="/foodscan" element={<FoodScan />} />
          <Route path="/workshop" element={<Workshop />} />
          <Route path="/headCount" element={<HeadCount />} />
          <Route path="/kitbag" element={<KitbagScan />} />
          <Route path="/products" element={<ProductsPage />} />
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/ScanBadge" element={<ScanBadge />} />
          <Route path="/SaveDelegateData/:code" element={<SaveDelegateData />} />
          <Route path="/ExbhitorDashboard" element={<ExbhitorDashboard />} />
         </Route>
        </Route>



        <Route path='' element={<AdminRoute/>}>
        <Route  element={<DashboardLayout />}>
        <Route path='/Admin' element={<Admin/>}/>
         </Route>
        </Route>

        

        <Route element={<SimpleLayout />}>
          <Route element={<LoginPage />} index />
          <Route path="/404" element={<Page404 />} />
          <Route path="*" element={<Navigate to="/404" />} />
        </Route>
      </Routes>
    </>
  );
        }
